import {React, useState} from 'react';
import {BrowserRouter, Routes, Route, useParams} from 'react-router-dom';
import HomePage from './HomePage';
import ModelPage from './ModelPage';
import {SceneContextProvider} from './SceneContext';
import {ChakraProvider} from '@chakra-ui/react'

import "./App.css";

function Container() {
    const [loading, setLoading] = useState(0);
    const {modelId} = useParams();

    return (loading || modelId) ? <ModelPage loading={loading} setLoading={setLoading}/> : <HomePage setLoading={setLoading}/>;
}

function App() {
    return (
        <ChakraProvider>
            <SceneContextProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Container/>} />
                        <Route path="/model/:modelId/:color/" element={<Container/>} />
                    </Routes>
                </BrowserRouter>
            </SceneContextProvider>
        </ChakraProvider>
    );
}

export default App;
