import {React} from 'react';
import {Canvas} from '@react-three/fiber';
import {OrbitControls, Environment, PerspectiveCamera, Preload} from '@react-three/drei';
import {Bloom, EffectComposer, ToneMapping} from '@react-three/postprocessing'
import {ToneMappingMode} from 'postprocessing'
import {Model} from "./Model";
import {useSearchParams} from 'react-router-dom';
import * as THREE from 'three';

const Scene = ({rendered, setRendered, setPrice, windowHeight, windowWidth}) => {
    let [searchParams] = useSearchParams();
    let screencap = searchParams.get('screencap') == 'yes';
    let videocap = searchParams.get('videocap') == 'yes';
    let embed = searchParams.get('embed') == 'yes';

    const DISTANCE_MAX = 37 + (screencap ? 4 : 0) + (videocap ? 12 : 0);
    const DISTANCE_MIN = 10;

    return (
        <div style={{width: "100vw", height: "100vh"}}>
            <Canvas shadows gl={{preserveDrawingBuffer: true}} id="reactcanvas" style={{backgroundColor: 'black'}}>
                <PerspectiveCamera makeDefault position={[0, (screencap || embed) ? 10000 : 22, -26]} fov={DISTANCE_MAX} />
                <OrbitControls autoRotate={videocap == true} autoRotateSpeed={15} enablePan={false} maxDistance={DISTANCE_MAX} minDistance={DISTANCE_MIN} enableRotate={true} minPolarAngle={0} maxPolarAngle={Math.PI*0.4} />
                <Environment
                    files="/env7.hdr"
                    resolution={1024}
                >
                    <group rotation={[-Math.PI / 3, 0, 0]}>
                        {/* <Lightformer intensity={4} rotation-x={Math.PI / 2} position={[0, 5, -9]} scale={[10, 10, 1]} /> */}
                        {/* {[2, 0, 2, 0, 2, 0, 2, 0].map((x, i) => (
                            <Lightformer key={i} form="circle" intensity={4} rotation={[Math.PI / 2, 0, 0]} position={[x, 4, i * 4]} scale={[4, 1, 1]} />
                        ))} */}
                        {/* <Lightformer intensity={2} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[50, 2, 1]} />
                        <Lightformer intensity={2} rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={[50, 2, 1]} /> */}
                    </group>
                    {/* <Stars radius={10} depth={50} count={5000} factor={2} saturation={0.3} fade speed={1} /> */}
                    {/* <Clouds material={THREE.MeshBasicMaterial}>
                        <Cloud segments={40} bounds={[10, 2, 2]} volume={10} color="orange" />
                        <Cloud seed={1} scale={2} volume={5} color="hotpink" fade={100} />
                    </Clouds> */}
                </Environment>
                <EffectComposer disableNormalPass>
                    <Bloom mipmapBlur luminanceThreshold={1} levels={8} intensity={0.5} />
                    <ToneMapping mode={ToneMappingMode.ACES_FILMIC} />
                </EffectComposer>
                <group scale={0.04} position={[0,0,2]}>
                    <Model receiveShadow rendered={rendered} setRendered={setRendered} setPrice={setPrice} windowHeight={windowHeight} windowWidth={windowWidth}/>
                </group>
                <Preload all />
            </Canvas>
        </div>
    );
}

export default Scene;


                // {/* <group position={[0, 0, 0]}>
                //     <AccumulativeShadows frames={100} color="#000000" colorBlend={5} toneMapped={true} alphaTest={0.9} opacity={1} scale={20} >
                //         <RandomizedLight amount={4} radius={10} ambient={0.5} intensity={1} position={[0, 10, -10]} size={15} mapSize={1024}  bias={0.0001} />
                //     </AccumulativeShadows>
                // </group> */}
