import React, {useState, useEffect} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useSceneContext, COLORS} from './SceneContext'; // Ensure this is correctly pointing to your context hook
import Scene from './Scene';
import Toolbar from "./components/Toolbar";
import Blob from "./Blob";
import {Canvas} from '@react-three/fiber';

export const ModelPage = ({loading, setLoading}) => {
    const navigate = useNavigate();
    const {offsetFactor, setOffsetFactor, lights, setLights} = useSceneContext();
    const {color, modelId} = useParams();
    const [rendered, setRendered] = useState(0);
    const [price, setPrice] = useState();
    const [windowWidth, setWindowWidth] = useState();
    const [windowHeight, setWindowHeight] = useState();

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const changeColor = () => {
        setLights(true);
        navigate('/model/' + modelId + '/' + Object.keys(COLORS)[(Object.keys(COLORS).indexOf(color) + 1) % Object.keys(COLORS).length]);
    };

    const toggleLights = () => {
        setLights(!lights);
    };

    return (
        <div>
            {(rendered < 2) && loading ? <div style={{padding: '8px', maxWidth: '400px', marginLeft: 'auto', marginRight: 'auto'}} className={{0: 'opacity0', 1: 'opacity0 fadeout', 2: 'fadein'}[loading]}>
                <div style={{height: '10vh'}}></div>
                <img src="neondelight.png" style={{ margin: 'auto', maxWidth: 200 }} alt="Logo" />
                <div>
                    <Canvas style={{height: '300px'}}>
                        <Blob/>
                    </Canvas>
                </div>
                <div className="loader2"></div>
                <br />
            </div> : null}
            {!loading ? <div id='thecanvasdiv' className={rendered === 2 ? 'fadein' : 'opacity0'}>
                <Scene rendered={rendered} setRendered={setRendered} setPrice={setPrice} windowWidth={windowWidth} windowHeight={windowHeight}/>
                <Toolbar
                    changeColor={changeColor}
                    toggleLights={toggleLights}
                    navigate={navigate}
                    price={price}
                    windowWidth={windowWidth}
                    windowHeight={windowHeight}
                />
            </div> : null}
        </div>
    );
}

export default ModelPage;
