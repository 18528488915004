import {createImage} from './helpers';

const getCroppedImg = async (imageSrc, percentCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = image.width * percentCrop.width / 100;
    canvas.height = image.height * percentCrop.height / 100;

    ctx.drawImage(
        image,
        image.width * percentCrop.x / 100,
        image.height * percentCrop.y / 100,
        canvas.width,
        canvas.height,
        0,
        0,
        canvas.width,
        canvas.height
    );

    return new Promise((resolve) => {
        canvas.toBlob((file) => {
            resolve(file);
        }, 'image/jpeg');
    });
};

export default getCroppedImg;
