import React, { createContext, useContext, useState } from 'react';
import * as THREE from 'three';

const SceneContext = createContext();

export const COLORS = {
    'r': '#ff4444',
    'g': '#00aa00',
    'b': '#44aaaa',
    'y': '#aaaa00',
    'p': '#e699ff',
    'x': '#d48f02',
};

export const SceneContextProvider = ({ children }) => {
    const [lights, setLights] = useState(true);
    const [offsetFactor, setOffsetFactor] = useState(2);
    const [camera, setCamera] = useState(new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000));
    const [rotation, setRotation] = useState(0); // Initial rotation angle in degrees
      // Function to toggle rotation
    const toggleRotation = () => {
        setRotation(prevRotation => (prevRotation === 0 ? -30 : 0)); // Toggle between 0 and 45 degrees
    };
    const value = {
        lights,
        setLights,
        camera,
        setCamera,
        offsetFactor,
        setOffsetFactor,
        rotation,
        toggleRotation
    };

    return (
        <SceneContext.Provider value={value}>
            {children}
        </SceneContext.Provider>
    );
};

export const useSceneContext = () => useContext(SceneContext);
