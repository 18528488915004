import {useGLTF, MeshTransmissionMaterial, Text, Plane, Center} from "@react-three/drei"
import {useEffect, useRef, useState} from "react";
import {useSceneContext, COLORS} from "./SceneContext";
import {useParams, useSearchParams} from 'react-router-dom';
import {useFrame, useLoader} from '@react-three/fiber';
import {TextureLoader} from 'three';

export const Cap = ({nodes, rendered, setRendered, index}) => {
    const {modelId} = useParams();
    const {lights} = useSceneContext()
    const {color} = useParams();
    const meshRef = useRef();
    useEffect(() => {
        if (meshRef.current) {
            meshRef.current.geometry.computeVertexNormals();
        }
    }, []);

    useFrame(() => {
        if (rendered < 2) {
            setRendered(rendered + 1);
        }
    });

    var thelights = lights;
    if (modelId == '9003630') {
        if (lights) {
            if (index == 5 || index == 6 || index == 7 || index == 8) {
                thelights = true;
            } else {
                thelights = false;
            }
        } else {
            if (index == 3 || index == 4 || index == 6 || index == 7 || index == 8) {
                thelights = true;
            } else {
                thelights = false;
            }
        }
    }

    return (
        <mesh
            ref={meshRef}
            castShadow
            receiveShadow
            geometry={nodes['geometry_' + index].geometry}
            material={nodes['geometry_' + index].material}
        >
            <meshPhysicalMaterial
                attach="material" // This line ensures the material is correctly attached
                color={thelights ? COLORS[color] : '#fff'} // Base color of the cube
                emissive={COLORS[color]} // Emissive color to make the object appear brighter
                emissiveIntensity={thelights ? 4.0 : 0.0} // Intensity of the emissive light
                clearcoat={1.0} // Enable clearcoat layer
                clearcoatRoughness={0.1} // Slightly rough surface for realistic reflections
                metalness={0.2} // Non-metallic material
                roughness={0.5} // Adjust for a slightly rough surface
                transmission={0.5} // Semi-transparent if needed for a glassy look
                reflectivity={0.5} // Adjust reflectivity for a more glass-like appearance
                ior={1.5} // Index of Refraction similar to glass
            />

        </mesh>
    );
};


export const BP = ({nodes}) => {
    return (
        <mesh
            castShadow
            receiveShadow
            geometry={nodes.geometry_1.geometry}
        >
            <meshPhysicalMaterial
                attach="material" // This line ensures the material is correctly attached
                color={'black'} // Base color of the cube
                clearcoat={0.0} // Enable clearcoat layer
                clearcoatRoughness={0.1} // Slightly rough surface for realistic reflections
                metalness={0.2} // Non-metallic material
                roughness={0.5} // Adjust for a slightly rough surface
                transmission={0.0} // Semi-transparent if needed for a glassy look
                reflectivity={0.0} // Adjust reflectivity for a more glass-like appearance
                ior={1.5} // Index of Refraction similar to glass
            />

        </mesh>
    );
};

export const Acryl = ({nodes}) => {
    const meshRef = useRef();
    useEffect(() => {
        if (meshRef.current) {
            meshRef.current.geometry.computeVertexNormals();
        }
    }, []);

    return (
        <mesh ref={meshRef} geometry={nodes.geometry_2.geometry}>
            <MeshTransmissionMaterial metalness={1.0}></MeshTransmissionMaterial>
        </mesh>
    );
};

export const Ruler = ({length}) => {
    const DIMENSIONS_TICKSIZE = 16;
    const DIMENSIONS_COLOR = '#aaa';
    const FONT_SIZE = 24;
    const FONT_DISTANCE = 24;

return (<group>
        <mesh>
            <boxGeometry args={[1, length, 1]} />
            <meshBasicMaterial color={DIMENSIONS_COLOR} />
        </mesh>
        <mesh position={[0, length / 2, 0]}>
            <boxGeometry args={[DIMENSIONS_TICKSIZE, 1, 1]} />
            <meshBasicMaterial color={DIMENSIONS_COLOR} />
        </mesh>
        <mesh position={[0, -length / 2, 0]}>
            <boxGeometry args={[DIMENSIONS_TICKSIZE, 1, 1]} />
            <meshBasicMaterial color={DIMENSIONS_COLOR} />
        </mesh>
        <Text position={[FONT_DISTANCE, 0, 0]} rotation={[0, 0, Math.PI/2]} fontSize={FONT_SIZE} color={DIMENSIONS_COLOR}>
            {Math.round(length / 10)} cm
        </Text>
    </group>);
}

export const Blackboard = () => {
    const texture = useLoader(TextureLoader, '/bb-3.png');
    const BLACKBOARD_SIZE = 1400;

    return (
        <mesh position={[0, 0, -12]}>
            <Plane args={[BLACKBOARD_SIZE, BLACKBOARD_SIZE]}>
                <meshBasicMaterial attach="material" map={texture} />
            </Plane>
        </mesh>
    )
}

export const Model = ({rendered, setRendered, setPrice, windowHeight, windowWidth}) => {
    const {modelId} = useParams();
    const {nodes} = useGLTF("/api/glb/" + modelId + '/');
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();
    let [searchParams] = useSearchParams();
    let screencap = searchParams.get('screencap') == 'yes';
    let videocap = searchParams.get('videocap') == 'yes';
    let embed = searchParams.get('embed') == 'yes';

    let scale = 1;
    if (windowWidth / windowHeight < (width / height) * 1.3) {
        scale = (windowWidth / windowHeight) / ((width / height) * 1.3);
    }

    useEffect(() => {
        async function fetchinfo() {
            const response = await fetch("/api/glb/" + modelId + '/info/');
            const json = await response.json();
            setPrice(json['price']);
            setWidth(json['width']);
            setHeight(json['height']);
        }
        fetchinfo();
    }, [modelId]);

    const DIMENSIONS_DISTANCE = 64;

    return (
        <group rotation={[-Math.PI/2, 0, Math.PI/2]} scale={(width && height) ? scale * 440 / height : 0}>
            {(screencap || videocap) ? null : <Blackboard/>}
            <Center>
                {(!(screencap || videocap) && width && height && modelId != '9003630') ? <group>
                    <group position={[height + DIMENSIONS_DISTANCE, width / 2, 0]}>
                        <Ruler length={width} />
                    </group>
                    <group position={[height / 2, -DIMENSIONS_DISTANCE, 0]} rotation={[0,0,-Math.PI/2]}>
                        <Ruler length={height} />
                    </group>
                </group> : null}
                <Acryl nodes={nodes} rendered={rendered}></Acryl>
                <BP nodes={nodes}/>
                {modelId == '9003630' ? (
                    <group>
                        <Cap nodes={nodes} rendered={rendered} setRendered={setRendered} index={3}/>
                        <Cap nodes={nodes} rendered={rendered} setRendered={setRendered} index={4}/>
                        <Cap nodes={nodes} rendered={rendered} setRendered={setRendered} index={5}/>
                        <Cap nodes={nodes} rendered={rendered} setRendered={setRendered} index={6}/>
                        <Cap nodes={nodes} rendered={rendered} setRendered={setRendered} index={7}/>
                        <Cap nodes={nodes} rendered={rendered} setRendered={setRendered} index={8}/>
                    </group>
                ) : (
                    <Cap nodes={nodes} rendered={rendered} setRendered={setRendered} index={0}/>
                )}
            </Center>
        </group>
    )
}
