import {Button} from "@chakra-ui/react";
import React from "react";


const DefaultButton = ({ children, ...props }) => {
    return (
        <Button
            colorScheme='teal'
            size="md" // default size
            variant='outline'
            {...props} // allows for overriding props when necessary
        >
            {children}
        </Button>
    );
};

export default DefaultButton;
