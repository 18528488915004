import React, {useState, useRef, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Input, Slider, SliderTrack, SliderFilledTrack, SliderThumb} from '@chakra-ui/react';
import DefaultButton from './components/DefaultButton';
import getCroppedImg from './utils/cropImage';
import {useSceneContext} from "./SceneContext";
import ReactCrop, {Crop} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

const CROP_INITIAL = {
    unit: '%',
    x: 0,
    y: 0,
    width: 100,
    height: 100
};

export const HomePage = ({setLoading}) => {
    const [image, setImage] = useState(null);
    const [showCropper, setShowCropper] = useState(false);
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const {setLights} = useSceneContext();

    const createModel = async (croppedImage) => {
        setLoading(2);
        window.scrollTo(0, 0);
        const formData = new FormData();
        formData.append('image', croppedImage);
        try {
            const response = await fetch('/api/upload/', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`Server responded with status ${response.status}`);
            }

            const responseData = await response.json();
            setLoading(1);
            setLights(true);
            setTimeout(function () {
                setLoading(0);
                navigate('/model/' + responseData.model_id + '/r');
            }, 200);
        } catch (error) {
            console.error("Error during image upload:", error);
            alert("Failed to upload the image. Please try again.");
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
                setShowCropper(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const chooseExample = (example) => {
        fetch(example)
            .then((response) => response.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.onload = () => {
                    setImage(reader.result);
                    setShowCropper(true);
                };
                reader.readAsDataURL(blob);
            })
            .catch((error) => console.error("Error fetching example image:", error));
    };

    const handleCropConfirm = useCallback(async () => {
        try {
            let thecrop = crop;
            if (crop.width == 0 && crop.height == 0) {
                thecrop = {unit: "%", x: 0, y: 0, width: 100, height: 100};
            }
            const croppedImage = await getCroppedImg(image, thecrop);
            createModel(croppedImage);
        } catch (e) {
            console.error(e);
        }
    });

    const handleBack = () => {
        setImage(null);
        setShowCropper(false);
        setCrop(CROP_INITIAL);
    };

    const [crop, setCrop] = useState(CROP_INITIAL);

    return (
        <div>
            <div style={{ maxWidth: '500px', margin: 'auto' }}>
                {!image && (
                    <div style={{paddingLeft: '8px', paddingRight: '8px'}}>
                        <a href="https://neondelight.de/">
                            <img src="neondelight.png" style={{ margin: 'auto', maxWidth: 200 }} alt="Logo" />
                        </a>
                        Um dein individuelles Neonschild zu designen, wähle ein Beispielbild aus der Gallerie aus oder lade ein eigenes Bild hoch.
                    </div>
                )}
                {showCropper && image && (
                    <div>
                        <div style={{width: '100%', paddingLeft: '16px', paddingRight: '16px'}}>
                            <br />
                            Wähle einen relevanten Bildausschnitt aus, falls notwendig.
                            <br />
                            <br />
                            <Button colorScheme="teal" style={{float: 'right'}} onClick={handleCropConfirm}>Auswahl bestätigen</Button>
                            <DefaultButton
                                colorScheme="teal"
                                onClick={handleBack}
                            >
                                Zurück
                            </DefaultButton>
                            <br />
                            <br />
                            <div style={{textAlign: 'center'}}>
                                <ReactCrop crop={crop} onChange={(crop, percentCrop) => setCrop(percentCrop)} style={{maxHeight: 'calc(max(100vh - 150px, 100px))'}}>
                                    <img src={image} />
                                </ReactCrop>
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                )}
            </div>
            {!image && (
                <div>
                    <br />
                    <div style={{ textAlign: 'center' }}>
                        <DefaultButton onClick={() => fileInputRef.current.click()}>Bild hochladen</DefaultButton>
                        <Input type="file" accept="image/png, image/jpeg" onChange={handleFileChange} ref={fileInputRef} hidden />
                    </div>
                    <br />
                    <div className="examples">
                        {Array.from({ length: 16 }, (_, i) => (
                            <div key={i}>
                                <div>
                                    <img
                                        src={`examples/example.${String(i).padStart(2, '0')}`}
                                        alt={`Example ${i}`}
                                        onClick={() => chooseExample(`examples/example.${String(i).padStart(2, '0')}`)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default HomePage;
